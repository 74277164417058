<template>
  <div
    class="time-range"
    :style="`margin-bottom: ${margin_bottom}; margin-right: ${margin_right}; width: ${width}`"
  >
    <span v-if="show_label" class="label-text">{{label_name}}</span>
<!--    <i v-if="loading" class="el-icon-loading color-primary" style="font-size: 20px; vertical-align: middle"/>-->
    <!-- <el-radio-group v-if="radio" v-model="time" :size="globalSize">
      <el-radio-button v-if="ranges.indexOf(1) > -1" :label="1">上月</el-radio-button>
      <el-radio-button v-if="ranges.indexOf(2) > -1" :label="2">上周</el-radio-button>
      <el-radio-button v-if="ranges.indexOf(3) > -1" :label="3">今日</el-radio-button>
      <el-radio-button v-if="ranges.indexOf(4) > -1" :label="4">本周</el-radio-button>
      <el-radio-button v-if="ranges.indexOf(5) > -1" :label="5">本月</el-radio-button>
      <el-radio-button v-if="ranges.indexOf(6) > -1" :label="6">今年</el-radio-button>
    </el-radio-group> -->
    <el-date-picker
      v-if="showPicker === true"
      :clearable="clearable"
      v-model="dateRange"
      :size="globalSize"
      type="monthrange"
      range-separator="至"
      start-placeholder="开始月份"
      :picker-options="pickerOptions"
      @change="changeDate()"
      end-placeholder="结束月份"
    >
    </el-date-picker>
  </div>
</template>
<script>
  import { RadioButton, RadioGroup } from 'element-ui'
  import extend from '../../js/function/extend'
  import { formatDay, getLastMonth, formatDate, getTimestamp } from '../../js/common/time'

  export default {
    name: 'monthRange',
    components: {
      [RadioButton.name]: RadioButton,
      [RadioGroup.name]: RadioGroup,
    },
    props: {
      select_time: {type: [String, Array], default: ''},
      width: {type: String, default: '280px'},
      margin_right: {type: String, default: '8px'},
      margin_bottom: {type: String, default: '8px'},
      radio: {type: Boolean, default: false},
      clearable: {type: Boolean, default: false},
      shortcuts: {type: Boolean, default: true}, // 是否显示快捷选项
      showPicker: {type: Boolean, default: true},
      // pickerOption: {type: Object, default: null},
      pickerOptionType: {type: Number, default: 0}, // 默认设置不限制时间，1-当前日期以及之后可选、2-当前日期之后可选、3-当前日期以及之前可选、4-当前日期之前可选
      ranges: {
        type: Array,
        default: () => {
          return [1, 2, 3, 4, 5, 6]
        }
      },
      show_label: {type: Boolean, default: true},
      label_name: {type: String, default: '日期'}
    },
    data() {
      return {
        time: 6,
        dateRange: [],
        options: {
          shortcuts: [{
            text: '本月',
            onClick(picker) {
              picker.$emit('pick', [new Date(), new Date()])
            }
          }, {
            text: '今年至今',
            onClick(picker) {
              const end = new Date()
              const start = new Date(new Date().getFullYear(), 0)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近六个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setMonth(start.getMonth() - 6)
              picker.$emit('pick', [start, end])
            }
          }],
        },
        dates: [
          { // 禁选今天及之前的日期
            disabledDate(time) {
              return time.getTime() <= Date.now() - 8.64e7
            }
          },
          { // 禁选今天之前的日期
            disabledDate(time) {
              return time.getTime() < Date.now()
            }
          },
          { // 禁选今天及之后的日期
            disabledDate(time) {
              return time.getTime() >= Date.now()
            }
          },
          { // 禁选今天及之后的日期
            disabledDate(time) {
              return time.getTime() > Date.now()
            }
          }
        ]
      }
    },
    computed: {
      globalSize() {
        return this.$store.state.size
      },
      pickerOptions() {
        let option = this.pickerOptionType === 0 ? {} : this.dates[this.pickerOptionType - 1]
        let initShortcuts = {}
        let initOptions = this.shortcuts ? this.options : initShortcuts
        return extend(option, initOptions)
      }
    },
    watch: {
      time() {
        this.generateTime()
      },
      select_time(val) {
        this.dateRange = this.deepCopy(val)
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.generateTime(this.time)
      },
      generateTime(time) {
        if (!time) time = this.time
        switch (time) {
          case 1:
            this.lastMonth()
            break
          case 2:
            this.lastWeek()
            break
          case 3:
            this.today()
            break
          case 4:
            this.week()
            break
          case 5:
            this.month()
            break
          case 6:
            this.year()
            break
        }
        this.changeDate()
      },
      lastMonth() {
        let time = formatDate()
        let start = time.Y + getLastMonth() + '-' + 1
        let endDay = time.Y + time.M + 1
        let timestamp = getTimestamp(endDay) - 1
        let end = formatDay(timestamp)
        this.dateRange = [start, end]
      },
      lastWeek() {
        let now = new Date()
        let startDay = now.getTime() - ((now.getDay() === 0 ? 7 + 6 : now.getDay() + 6) * 86400 * 1000)
        let endDay = now.getTime() - ((now.getDay() === 0 ? 7 : now.getDay()) * 86400 * 1000)
        let start = formatDay(startDay)
        let end = formatDay(endDay)
        this.dateRange = [start, end]
      },
      today() {
        let date = formatDay()
        this.dateRange = [date, date]
      },
      week() {
        let now = new Date()
        let startDay = now.getTime() - ((now.getDay() === 0 ? 7 - 1 : now.getDay() - 1) * 86400 * 1000)
        let start = formatDay(startDay)
        let end = formatDay()
        this.dateRange = [start, end]
      },
      month() {
        let time = formatDate()
        let start = time.Y + time.M + 1
        let end = time.Y + time.M + time.D
        this.dateRange = [start, end]
      },
      year() {
        let time = formatDate()
        let start = time.Y + '01-01'
        let end = time.Y + time.M + time.D
        this.dateRange = [start, end]
      },
      changeDate() {
        let range = []
        if (this.dateRange && this.dateRange.length > 0) {
          range[0] = formatDay(this.dateRange[0])
          range[1] = formatDay(this.dateRange[1])
        }
        this.$emit('change', range, this.time)
        this.$emit('update:select_time', range)
      }
    },
  }
</script>

<style lang="scss" scoped>
  .time-range {
    display: inline-flex;
    align-items: center;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    box-sizing: border-box;
    background: #fff;
    .label-text {
      flex-shrink: 0;
      padding-left: 10px;
    }
  }
  ::v-deep .el-date-editor .el-range-separator {
    width: 10%;
  }
  ::v-deep .el-input__inner {
    border: none;
    height: 34px;
  }
</style>
