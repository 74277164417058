import router from '../../router'
import store from '../../store'
import { Message, Loading, Notification, MessageBox } from 'element-ui'

let loading // 定义loading变量
let topMsg // 定义message变量

/**
 * 加载中提示
 * @param message
 * @param duration  时长
 */
export const loadings = (message = '加载中...', duration = 1500) => {
  loading = Loading.service({
    lock: true,
    body: true,
    text: message,
    duration: duration,
    background: 'rgba(0, 0, 0, 0)',
  })
}

/**
 * 关闭loading提示
 */
export const clear = () => {
  loading.close()
}

/**
 * 顶部消息提示
 * @param message
 * @param type  error-失败，success-成功，warning-警告，info-消息
 * @param duration  自定义时长，时长(ms)，值为 0 时，toast 不会消失
 */
export const topMessage = (message, type = 'warning', onClose = () => {}, duration = 3000) => {
  topMsg = Message({
    message: message,
    type: type,
    duration: duration,
    showClose: true,
    onClose: onClose,
    // offset: document.documentElement.clientHeight/2 - 25, // 在页面中间弹出提示
  })
}

/**
 * 关闭topMessage提示
 */
export const topMsgClear = () => {
  topMsg.close()
}

/**
 * 通知-悬浮出现在页面角落
 * @param message
 * @param type
 * @param title
 */
export const notify = (message, type = 'error', title = '提示', duration = 3000) => {
  Notification({
    message: message,
    title: title,
    type: type,
    showClose: true,
    offset: 30,
    duration: duration
  })
}

/**
 * 消息弹框(确认)
 * @param message
 * @param title
 * @param action
 */
export const alertBox = (message, title = '提示', sure = () => {}) => {
  MessageBox.alert(message, title, {
    confirmButtonText: '确定',
    showClose: false,
    type: 'warning',
    center: true
  }).then(() => {
    sure && sure()
  })
}

/**
 * 消息弹框(确认、取消)
 * @param message
 * @param title
 */
export const confirmBox = (message, title = '提示', success = () => {}, cancel = () => {}) => {
  MessageBox.confirm(message, title, {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    showClose: false,
    type: 'warning',
    center: true
  }).then(() => {
    success && success()
  }).catch(() => {
    cancel && cancel()
  })
}

/**
 * 处理返回信息的展现方式
 * @param response
 */
export const handleResponse = (response) => {
  if (Object.prototype.hasOwnProperty.call(response, 'message')) {
    switch (response.message_case) {
      case 'box':
        alertBox(response.message, response.message_type)
        return
      case 'notify':
        return notify(response.message, response.message_type)
      case 'message':
        return topMessage(response.message, response.message_type)
      default:
        return topMessage(response.message, response.message_type)
    }
  }
}

/**
 * 根据返回结果，展现返回消息
 * @param response
 * @param type
 * @return {void|(undefined|void)}
 */
export const responseRoute = (response) => {
  let hasCode = Object.prototype.hasOwnProperty.call(response, 'status_code')
  let hasMsg = Object.prototype.hasOwnProperty.call(response, 'message')
  let hasMsgType = Object.prototype.hasOwnProperty.call(response, 'message_type')
  let message_type = hasMsgType ? response.message_type : 'error'
  if (hasCode && hasMsg) {
    response = {code: Number(response.status_code), message: response.message, message_type: message_type}
  }
  switch (response.code) {
    case 200:
      break
    case 201:
      break
    case 801: // token无效，请重新登陆
      handleResponse({message_case: 'message', message: response.message, message_type: response.message_type})
      setTimeout(() => {
        // 退出登陆
        store.commit('logout')
        router.push({name: 'login'})
      }, 1500)
      break
    default:
      handleResponse({message_case: 'message', message: response.message, message_type: response.message_type})
      break
  }
}

export const errorResponse = error => {
  let response = {}
  if (Object.prototype.hasOwnProperty.call(error, 'response') && error.response && error.response.data) {
    response = error.response.data
    let hasCode = Object.prototype.hasOwnProperty.call(response, 'code')
    let hasMsg = Object.prototype.hasOwnProperty.call(response, 'message')
    if (!hasCode) response.code = error.response.status
    if (!hasMsg || response.message === '') response.message = 'Network Error'
  } else {
    response.message = 'Network Error'
    response.message_case = 'message'
    response.code = 404
  }
  responseRoute(response)
}

export default {
  loadings,
  clear,
  topMessage,
  topMsgClear,
  notify,
  alertBox,
  confirmBox,
  handleResponse,
  responseRoute,
  errorResponse,
}
