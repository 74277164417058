<template>
  <el-button
    :disabled="disabled"
    :size="globalSize"
    :type="type"
    @click="clickBtn"
    :plain="plain"
    v-if="checkRule(rules)"
    class="icon-button"
    :style="`margin-bottom: ${margin_bottom}; margin-right: ${margin_right}`"

  >
    <i v-if="icon" :class="icon" class="icon color-primary" />{{name}}
  </el-button>
</template>

<script>
import { checkRule } from '../../js/common/util'

export default {
  name: 'iconButton',
  props: {
    rules: { type: Array, default() { return [] } },
    disabled: { type: Boolean, default: false },
    plain: { type: Boolean, default: true },
    name: { type: String, default: '添加' },
    type: { type: String, default: 'default' },
    icon: { type: String, default: 'el-icon-plus color-primary' },
    sub: { type: String, default: '' },
    margin_right: {type: String, default: '8px'},
    margin_bottom: {type: String, default: '8px'},
  },
  computed: {
    globalSize() {
      return this.$store.state.size
    }
  },
  methods: {
    checkRule,
    clickBtn() {
      this.$emit('clickBtn')
    }
  }
}
</script>

<style lang="scss" scoped>
  .icon-button {
    margin-left: 0;
    /*margin-bottom: 8px;*/
  }
  .icon {
    font-weight: 600;
    padding-right: 3px;
  }
</style>
