<template>
  <div class="header">
    <div class="header-logo">
      <img src="../../../assets/logo.png" class="logo" alt="">
      <span v-if="sidebarOpened" class="title">{{projectName}}</span>
    </div>
    <div class="calendar">
      <i class="icon iconfont riqi"/>
      <span>
        {{'星期' + clock.week}}  {{clock.year}}/{{clock.month}}/{{clock.day}} {{clock.hour}}:{{clock.minutes}}:{{clock.second}}
      </span>
    </div>
    <div class="action-area">
<!--      <div class="icon-wrap location" @click="changePlatform">-->
<!--        <span class="text"><i class="icon iconfont dizhi"/>{{cur_platform.platform_name}}</span>-->
<!--      </div>-->
      <div class="icon-wrap user">
        <el-dropdown trigger="click" size="mini">
          <span class="text">
            <i class="icon iconfont yonghu"/>
            {{user_info.user_name}}
            <i class="icon el-icon-arrow-down el-icon--right"/>
          </span>
          <el-dropdown-menu slot="dropdown" class="drop-down-menu">
            <el-dropdown-item>
              <el-row @click.native="changePass" class="item-menu">
                <el-col :span="8" align="center"><i class="iconfont mima color-primary">&nbsp;</i></el-col>
                <el-col :span="16" align="left"><b>变更密码</b></el-col>
              </el-row>
            </el-dropdown-item>
            <el-dropdown-item>
              <el-row @click.native="loginOut" class="item-menu">
                <el-col :span="8" align="center"><i class="iconfont tuichu color-danger">&nbsp;</i></el-col>
                <el-col :span="16" align="left" class="color-danger"><b>退出</b></el-col>
              </el-row>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
<!--      <div class="icon-wrap sign" @click="loginOut">-->
<!--        <i class="icon iconfont tuichu"/>-->
<!--        <span class="text">退出</span>-->
<!--      </div>-->
    </div>
    <change-pass ref="changePass"/>
    <change-platform ref="changePlatform"/>
  </div>
</template>

<script>
  import changePass from './changePass'
  import changePlatform from './changePlatform'
  import { getWeek } from '../../../js/common/time'

  export default {
    name: '',
    components: {
      changePass,
      changePlatform,
    },
    data() {
      return {
        clock: {}
      }
    },
    computed: {
      // cur_platform() {
      //   return this.$store.state.cur_platform
      // },
      user_info() {
        return this.$store.state.user_info
      },
      sidebarOpened() {
        return this.$store.state.sidebarOpened
      },
      projectName() {
        return this.$store.state.project.nameZh
      }
    },
    created() {
      this.timer()
    },
    mounted() {
      this.$nextTick(() => {
        setInterval(this.timer, 1000)
      })
    },
    methods: {
      // 当前时间
      timer() {
        let now = new Date()
        this.clock = {
          year: now.getFullYear(),
          month: now.getMonth() < 9 ? '0' + (now.getMonth() + 1) : now.getMonth() + 1,
          day: now.getDate() < 10 ? '0' + now.getDate() : now.getDate(),
          week: getWeek(now.getDay()),
          hour: now.getHours() < 10 ? '0' + now.getHours() : now.getHours(),
          minutes: now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes(),
          second: now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds()
        }
      },
      changePlatform() {
        this.$refs.changePlatform.open()
      },
      changePass() {
        this.$refs.changePass.dialog = true
      },
      loginOut() {
        this.$msg.confirmBox('确认退出?', '提示', () => {
          this.$store.commit('logout')
          this.$router.push({ name: 'login' })
          this.$msg.topMessage('退出成功！', 'success')
        })
      }
    }
  }
</script>

<style lang="scss" src="./navBar.scss" scoped/>
