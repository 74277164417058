import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '../layout/layout'

// 解决路由切换时路由重复点击所导致的错误
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

/**
 * 基础路由
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const baseRoutes = [
  {
    path: '/',
    name: 'login_',
    redirect: '/login',
    component: { render: (e) => e('router-view') },
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "base" */ '@/views/login/login.vue')
      }
    ]
  },
  // {
  //   path: '/switchAccount',
  //   name: 'switchAccount_',
  //   redirect: '/switchAccount',
  //   component: { render: (e) => e('router-view') },
  //   children: [
  //     {
  //       path: '/switchAccount',
  //       name: 'switchAccount',
  //       component: () => import(/* webpackChunkName: "base" */ '@/views/switchAccount/index.vue')
  //     }
  //   ]
  // },
  // {
  //   path: '/home',
  //   name: 'home_',
  //   component: layout,
  //   children: [
  //     {
  //       path: '/home',
  //       name: 'home',
  //       meta: { title: '首页', icon: 'iconfont shouye', affix: true, showingChildren: true }, // affix tabs上固定显示
  //       component: () => import(/* webpackChunkName: "base" */ '@/views/home/home.vue')
  //     }
  //   ]
  // },
  // {
  //   path: '/keywordReply',
  //   name: 'keywordReply_',
  //   component: layout,
  //   children: [
  //     {
  //       path: '/keywordReply',
  //       name: 'keywordReply',
  //       meta: { title: '关键词回复', icon: 'iconfont caozuorizhi', affix: true, showingChildren: true },
  //       component: () => import(/* webpackChunkName: "base" */ '@/views/keywordReply/index.vue')
  //     }
  //   ]
  // },
  {
    path: '/business',
    name: 'business_',
    component: layout,
    children: [
      {
        path: '/business',
        name: 'business',
        meta: { title: '商家管理', icon: 'iconfont shangjia', showingChildren: true },
        component: () => import(/* webpackChunkName: "base" */ '@/views/business/index.vue')
      }
    ]
  }, {
    path: '/star',
    name: 'star_',
    component: layout,
    children: [
      {
        path: '/star',
        name: 'star',
        meta: { title: '星级管理', icon: 'iconfont jiagong', showingChildren: true },
        component: () => import(/* webpackChunkName: "base" */ '@/views/star/index.vue')
      }
    ]
  }, {
    path: '/cuisine',
    name: 'cuisine_',
    component: layout,
    children: [
      {
        path: '/cuisine',
        name: 'cuisine',
        meta: { title: '菜系管理', icon: 'iconfont caipinchengben', showingChildren: true },
        component: () => import(/* webpackChunkName: "base" */ '@/views/cuisine/index.vue')
      }
    ]
  }, {
    path: '/businessDistrict',
    name: 'businessDistrict_',
    component: layout,
    children: [
      {
        path: '/businessDistrict',
        name: 'businessDistrict',
        meta: { title: '商圈管理', icon: 'iconfont chanpinzhongxin', showingChildren: true },
        component: () => import(/* webpackChunkName: "base" */ '@/views/businessDistrict/index.vue')
      }
    ]
  }, {
    path: '/scene',
    name: 'scene_',
    component: layout,
    children: [
      {
        path: '/scene',
        name: 'scene',
        meta: { title: '场景管理', icon: 'iconfont jinhuo', showingChildren: true },
        component: () => import(/* webpackChunkName: "base" */ '@/views/scene/index.vue')
      }
    ]
  }, {
    path: '/region',
    name: 'region_',
    component: layout,
    children: [
      {
        path: '/region',
        name: 'region',
        meta: { title: '区域管理', icon: 'iconfont lingliaobaosun', showingChildren: true },
        component: () => import(/* webpackChunkName: "base" */ '@/views/region/index.vue')
      }
    ]
  }, {
    path: '/advert',
    name: 'advert_',
    component: layout,
    children: [
      {
        path: '/advert',
        name: 'advert',
        meta: { title: '广告管理', icon: 'iconfont zhinan', showingChildren: true },
        component: () => import(/* webpackChunkName: "base" */ '@/views/advert/index.vue')
      }
    ]
  }, {
    path: '/diamondsSetting',
    name: 'diamondsSetting_',
    component: layout,
    children: [
      {
        path: '/diamondsSetting',
        name: 'diamondsSetting',
        meta: { title: '金刚区设置', icon: 'iconfont chengbenzhanbifenxi', showingChildren: true },
        component: () => import(/* webpackChunkName: "base" */ '@/views/diamondsSetting/index.vue')
      }
    ]
  }, {
    path: '/articleSettings',
    name: 'articleSettings_',
    component: layout,
    children: [
      {
        path: '/articleSettings',
        name: 'articleSettings',
        meta: { title: '精选文章设置', icon: 'iconfont chanpinzhongxin', showingChildren: true },
        component: () => import(/* webpackChunkName: "base" */ '@/views/articleSettings/index.vue')
      }
    ]
  }, {
    path: '/customerService',
    name: 'customerService_',
    component: layout,
    children: [
      {
        path: '/customerService',
        name: 'customerService',
        meta: { title: '客服列表', icon: 'iconfont yonghuzhongxin', showingChildren: true },
        component: () => import(/* webpackChunkName: "base" */ '@/views/customerService/index.vue')
      }
    ]
  }, {
    path: '/feedback',
    name: 'feedback_',
    component: layout,
    children: [
      {
        path: '/feedback',
        name: 'feedback',
        meta: { title: '反馈列表', icon: 'iconfont meirirenwu', showingChildren: true },
        component: () => import(/* webpackChunkName: "base" */ '@/views/feedback/index.vue')
      }
    ]
  },
  // {
  //   path: '/accountManage',
  //   name: 'accountManage_',
  //   component: layout,
  //   children: [
  //     {
  //       path: '/accountManage',
  //       name: 'accountManage',
  //       meta: { title: '账号管理', icon: 'iconfont xitongzhongxin', showingChildren: true },
  //       component: () => import(/* webpackChunkName: "base" */ '@/views/accountManage/index.vue')
  //     }
  //   ]
  // }
]

const createRouter = () => new VueRouter({
  mode: 'history', // 需要开启服务
  base: '/aokeappletcms/',
  scrollBehavior: () => ({ y: 0 }),
  routes: baseRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
