<template>
  <el-dropdown
    class="dropdown-box"
    @command="handleCommand"
    :style="`margin-bottom: ${margin_bottom}; margin-right: ${margin_right}`"
  >
    <el-button type="default" class="button">
      {{name}}<i class="el-icon-caret-bottom icon" />
    </el-button>
    <el-dropdown-menu slot="dropdown" class="header-new-dropdown">
      <el-dropdown-item
        v-for="(item, index) in list"
        :key="index"
        :command="item.command"
        :divided="item.divided"
        :disabled="(item.hasOwnProperty('disabled') && item.disabled) || (item.rules && !checkRule(item.rules))"
      >
        <span v-if="!item.name.includes('导入')">{{item.name}}</span>
        <el-upload
          v-else
          name="excel"
          accept=".xlsx,.xls"
          :headers="header"
          :action="getApiUrl() + (auth ? `/${propName[auth].name}/importdetail` : '')"
          :data="handlePostData(item)"
          :on-change="onChange"
          :on-error="onError"
          :show-file-list="false"
        >
          <span class="upload-text">{{item.name}}</span>
        </el-upload>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
  import { checkRule, exportData } from '../../js/common/util'
  import { getApiUrl } from '../../js/common/axios'

  export default {
    name: 'dropdown',
    props: {
      rules: { type: Array, default() { return [] } },
      list: { type: Array, default() { return [] } },
      plain: { type: Boolean, default: true },
      name: { type: String, default: '更多' },
      type: { type: String, default: 'default' },
      sub: { type: String, default: '' },
      margin_right: {type: String, default: '8px'},
      margin_bottom: {type: String, default: '8px'},
      form: { type: Object, default() { return {} } },
      auth: {type: String, default: ''},
    },
    data() {
      return {
        header: this.$store.state.header,
        commonData: this.$store.state.commonParams,
        propName: {
          '0405': {id: 'third_takeout_id', name: 'thirdtakeout'}, // 外卖录入
        },
      }
    },
    methods: {
      checkRule,
      getApiUrl,
      handlePostData(item) {
        let data = this.deepCopy(this.commonData)
        if (this.auth) {
          let obj = {
            [this.propName[this.auth].id]: this.form[this.propName[this.auth].id]
          }
          data = {...data, ...obj}
        }
        if (item.postData) data = {...data, ...item.postData}
        return data
      },
      onSuccess(response, file, fileList) {
        if (response.status_code == 200 || response.status_code == 201) {
          this.$msg.topMessage('导入成功', 'success')
          this.$emit('refresh')
        } else {
          this.$msg.topMessage(response.message)
        }
      },
      onError(err, file, fileList) {
        this.$msg.topMessage(err.message)
      },
      // 导入
      onChange(file, fileList) {
        switch (file.status) {
          case 'ready':
            this.$msg.topMessage('数据导入中，请稍后查看')
            break
          case 'success':
            if (file.response.status_code == 200 || file.response.status_code == 201) {
              this.$msg.topMessage('导入成功', 'success')
              this.$emit('refresh')
            } else {
              this.$msg.topMessage(file.response.message)
            }
            break
          case 'fail':
            // this.$msg.topMessage('导入失败')
            break
        }
      },
      // 导出
      exportDetail() {
        var url = `/${this.propName[this.auth].name}/batch_export`
        var postData = {
          [`${this.propName[this.auth].id}s`]: this.form[this.propName[this.auth].id],
        }
        exportData(postData, url)
      },
      handleCommand(command) {
        if (command) {
          if (command == 'exportDetail') this[command]()
          else if (command != 'importDetail') this.$emit('handleCommand', command)
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .dropdown-box {
    .button {
      padding-right: 10px;
      padding-left: 10px;
      font-weight: 400;
      color: $font-c-1;
    }
    .button:hover {
      color: #589df8;
    }
    .icon {
      font-weight: 600;
      padding-left: 3px;
    }
  }
  .upload-text {
    display: inline-block;
    width: 100%;
    text-align: left;
  }
  ::v-deep .el-upload {
    display: block;
  }
  ::v-deep .el-upload-dragger {
    width: 100%;
  }
</style>
<style lang="scss">
  .header-new-dropdown li {
    color: $font-c-1;
  }
</style>
