<template>
  <div>
    <el-dialog
      :before-close="close"
      :title="title"
      :visible.sync="dialog"
      append-to-body
      top="8vh"
      class="small-dialog"
      width="45%"
      :close-on-click-modal="false">
      <template slot="title">
        <span class="dialog-title">{{title}}
          <small style="color: #409EFF;">(当前企业：{{cur_platform.platform_name}})</small>
        </span>
      </template>
      <div style="height: 42vh; margin-left: 2vh; overflow: auto">
        <el-row>
          <el-col :span="5" v-for="(item, index) in platform_list" :key="index" :offset="index % 4 === 0 ? 0 : 1">
            <el-card :body-style="{ padding: '0px' }" style="margin-bottom: 2vh">
              <img :src="default_png" style="width: 100%; cursor: pointer" @click="changePlatform(item)">
              <div style="padding: 14px;">
                <strong>{{item.platform_name}}</strong>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'changePlatform',
    data() {
      return {
        title: '选择企业',
        dialog: false,
        default_png: require('../../../assets/default_shop.png'),
      }
    },
    computed: {
      platform_list() {
        return this.$store.state.platform_list
      },
      cur_platform() {
        return this.$store.state.cur_platform
      },
    },
    methods: {
      open() {
        this.dialog = true
      },
      close() {
        this.dialog = false
      },
      // 切换企业
      changePlatform(item) {
        let that = this
        if (item.platform_id === that.cur_platform.platform_id) return that.$msg.topMessage('已在当前企业！')
        that.$store.commit('set_cur_platform', item)
        that.$msg.topMessage('切换成功！', 'success', () => {
          that.close()
          // that.$router.push({name: that.$store.state.homeRouter})
          setTimeout(() => {
            location. reload()
          }, 300)
        }, 1500)
      }
    }
  }
</script>
