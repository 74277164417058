<template>
  <el-dropdown
    class="dropdown-box"
    @command="handleCommand"
    :style="`margin-bottom: ${margin_bottom}; margin-right: ${margin_right}`"
  >
    <el-button type="default" class="button">
      打印<i class="el-icon-caret-bottom icon" />
    </el-button>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="(item, index) in list"
        :key="index"
        :command="item.command"
        :divided="item.divided"
        :disabled="disabled || (auth && !checkRule([`${auth}${item.type}`]))"
      >
        <span class="dropdown-item">{{item.name}}</span>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
  import { checkRule } from '../../js/common/util'

  export default {
    name: 'printDropdown',
    props: {
      rules: { type: Array, default() { return [] } },
      disabled: { type: Boolean, default: false },
      plain: { type: Boolean, default: true },
      name: { type: String, default: '更多' },
      type: { type: String, default: 'default' },
      sub: { type: String, default: '' },
      margin_right: {type: String, default: '8px'},
      margin_bottom: {type: String, default: '8px'},
      auth: {type: String, default: ''},
    },
    data() {
      return {
        list: [
          {command: 'dropPrint', name: '打印', type: '09'},
          // {command: '', name: '打印格式', type: '01'},
          // {command: '', name: '打印格式导出', type: '07'},
        ],
      }
    },
    methods: {
      checkRule,
      handleCommand(command) {
        if (command) this.$emit('handleCommand', command)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .dropdown-box {
    .button {
      padding-right: 10px;
      padding-left: 10px;
      font-weight: 400;
    }
    .icon {
      font-weight: 600;
      padding-left: 3px;
    }
    .dropdown-item {
      color: $font-c-1!important;
    }
  }
</style>
