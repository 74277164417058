<template>
  <el-pagination
    class="paging-box"
    :align="paginationAlign"
    :background="background"
    :current-page="page"
    :layout="layout[layout_type]"
    :page-size="size"
    :page-sizes="generateSizes()"
    :pager-count="paginationCount"
    :small="smallPagination"
    :total="total"
    @current-change="pageChange"
    @size-change="sizeChange">
  </el-pagination>
</template>

<script>
import { Pagination } from 'element-ui'

export default {
  name: 'pagination',
  components: {
    [Pagination.name]: Pagination
  },
  data() {
    return {
      layout: {
        0: '',
        1: 'total, prev, pager, next',
        2: 'total, prev, pager, next, jumper',
        3: 'total, sizes, prev, pager, next',
        4: 'prev, pager, next',
        5: 'prev, pager, next, jumper',
        9: 'total, sizes, prev, pager, next, jumper'
      }
    }
  },
  props: {
    background: { type: Boolean, default: true },
    layout_type: { type: Number, default: 9 },
    paginationAlign: { type: String, default: 'right' },
    paginationCount: { type: Number, default: 7 },
    // TODO 数据有问题
    paginationSizes: {
      type: Array, default() {
        return [10, 20, 50, 100, 200, 500]
      }
    },
    smallPagination: { type: Boolean, default: false },
    size: { type: Number, default: 50 },
    page: { required: true, type: Number, default: 1 },
    total: { required: true, type: Number, default: 0 }
  },
  methods: {
    generateSizes() {
      let pageSizes = [this.size].concat(this.paginationSizes)
      pageSizes = [...new Set(pageSizes)]
      pageSizes.sort((a, b) => {
        return a-b
      })
      return pageSizes
    },
    sizeChange(size) {
      this.$emit('update:size', size)
    },
    pageChange(page) {
      this.$emit('update:page', page)
    }
  }
}
</script>

<style lang="scss" scoped>
  .paging-box {
    padding: 15px 15px 15px 0;
    background: #fff;
    box-sizing: border-box;
  }
</style>
