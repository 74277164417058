<template>
  <div class="menu-wrapper" v-if="!item.hidden">
    <template v-if="hasOneShowingChild(item.children, item) && (!onlyOneChild.children || onlyOneChild.noShowingChildren) && !item.alwaysShow">
      <app-link :to="resolvePath(onlyOneChild.path)" v-if="onlyOneChild.meta">
        <el-menu-item :class="{'submenu-title-noDropdown':!isNest}" :index="resolvePath(onlyOneChild.path)" :align="isCollapse ? 'left' : 'center'">
          <i :class="onlyOneChild.meta.icon" class="menu-icon" @click="toggleSideBar(onlyOneChild.meta.icon)"/>
          <span class="menu-title" slot="title">{{onlyOneChild.meta.title}}</span>
        </el-menu-item>
      </app-link>
    </template>
    <el-submenu v-else-if="item.meta" ref="subMenu" :index="resolvePath(item.path)" :align="isCollapse ? 'left' : 'center'" popper-append-to-body>
      <template slot="title">
        <i :class="item.meta.icon" class="menu-icon"/>
        <span class="menu-title" slot="title" v-show="isCollapse">{{item.meta.title}}</span>
      </template>
      <side-bar-item
        :base-path="resolvePath(child.path)"
        :is-nest="true"
        :item="child"
        :key="child.path"
        class="nest-menu"
        v-for="child in item.children"
      />
    </el-submenu>
  </div>
</template>

<script>
  import path from 'path'
  import appLink from './link'
  import {isExternal} from '../../../js/common/util'

  export default {
    name: 'SideBarItem',
    components: {
      appLink
    },
    props: {
      item: {type: Object, required: true},
      isNest: {type: Boolean, default: false},
      basePath: {type: String, default: ''}
    },
    computed: {
      isCollapse() {
        return Number(this.$store.state.sidebarOpened)
      }
    },
    data() {
      return {
        onlyOneChild: null
      }
    },
    methods: {
      hasOneShowingChild(children = [], parent) {
        let showingChildren = children.filter(item => {
          if (item.hidden) {
            return false
          } else {
            // Temp set(will be used if only has one showing child)
            //临时设置（只有一个显示子项时使用）
            this.onlyOneChild = item
            return true
          }
        })
        // When there is only one child router, the child router is displayed by default
        //当只有一个子路由器时，默认显示子路由器
        if (showingChildren.length === 1 && showingChildren[0].meta && showingChildren[0].meta.showingChildren) {
          return true
        }
        // Show parent if there are no child router to display
        //如果没有要显示的子路由器，则显示父路由器
        if (showingChildren.length === 0) {
          this.onlyOneChild = {...parent, path: '', noShowingChildren: true}
          return true
        }
        return false
      },
      resolvePath(routePath) {
        if (isExternal(routePath)) {
          return routePath
        }
        if (isExternal(this.basePath)) {
          return this.basePath
        }
        return path.resolve(this.basePath, routePath)
      },
      toggleSideBar(icon) {
        if (icon === 'el-icon-more') this.$emit('toggleSideBar')
      }
    }
  }
</script>

<style lang="scss" src="./sidebar.scss" scoped/>
