// import variables from '../../styles/variables.scss'

/**
 * 自动调整表格高度
 * @param navH 页面头部高度
 * @param tabsViewH tabs高度
 * @param toolH tool-bar高度
 * @param appMainP app-main padding 12px
 * @param paginationH 页码高度58px
 * @return height 表格高度
 */
export default function (paginationH = 58) {
  // let navH = Number(variables.navHeight.replace('px', ''))
  // let tabsViewH = Number(variables.tabsViewHeight.replace('px', ''))
  let toolH = document.getElementsByClassName('tool-bar')[0] ? document.getElementsByClassName('tool-bar')[0].clientHeight : 0
  let appMainH = document.getElementsByClassName('app-main')[0] ? document.getElementsByClassName('app-main')[0].clientHeight : 0
  const appMainP = 12
  // let tableH = document.body.clientHeight - navH - tabsViewH - toolH - appMainP - paginationH
  let tableH = appMainH - toolH - appMainP - paginationH
  return tableH
}
