<template>
  <div
    class="date-picker"
    :style="`margin-bottom: ${margin_bottom}; margin-right: ${margin_right}; width: ${width}`"
  >
    <span v-if="show_label" class="label-text">{{label_name}}</span>
    <el-date-picker
          :disabled="disabled"
          v-model="value"
          :picker-options="date_range === 1 ? this.beforeDate : (date_range === 2 ? this.afterDate : {})"
          :format="format"
          :value-format="format"
          :type="type"
          :width="width"
          :placeholder="placeholder"
          :clearable="clearable"
          @change="change"
          :size="globalSize"
          style="width: 100%"
    />
  </div>
</template>

<script>
  import { formatDay, formatDate } from '../../js/common/time'

  export default {
    name: 'datePicker',
    props: {
      time: {type: String, default: ''},
      disabled: {type: Boolean, default: false},
      date_range: {type: Number, default: 3},
      type: {type: String, default: 'date'}, // year/month/date/dates/months/years week/datetime/datetimerange/ daterange/monthrange
      width: {type: String, default: '172px'},
      margin_right: {type: String, default: '8px'},
      margin_bottom: {type: String, default: '8px'},
      placeholder: {type: String, default: '请选择时间'},
      clearable: {type: Boolean, default: true},
      show_label: {type: Boolean, default: true},
      label_name: {type: String, default: '日期'}
    },
    data() {
      return {
        value: '',
        beforeDate: {
          disabledDate(time) {
            return time.getTime() >= Date.now() + 1 // 加1毫秒获取此刻的时间点，解决点击此刻无效
          }
        },
        afterDate: {
          disabledDate(time) {
            return time.getTime() <= Date.now() - 8.64e7 // - 8.64e7 表示可选择当天时间
          }
        }
      }
    },
    created() {
      if (!this.time) {
        this.init()
      }
    },
    computed: {
      globalSize() {
        return this.$store.state.size
      },
      format() {
        let format = ''
        if (this.type === 'date') format = 'yyyy-MM-dd'
        if (this.type === 'datetime') format = 'yyyy-MM-dd HH:mm:ss'
        if (this.type === 'year') format = 'yyyy'
        if (this.type === 'month') format = 'yyyy-MM'
        if (this.type === 'week') format = 'WW'
        return format
      }
    },
    methods: {
      init() {
        if (this.type === 'date') this.value = formatDay()
        if (this.type === 'datetime') this.value = formatDate().time
        if (this.type === 'year') this.value = formatDate().Y.slice(0, 4)
        if (this.type === 'month') this.value = formatDay().slice(0, 7)
        this.change()
      },
      change() {
        this.$emit('update:time', this.value)
      },
      clear() {
        this.init()
      }
    },
    watch: {
      time: {
        handler(val) {
          this.value = val
        },
        immediate: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .date-picker {
    display: inline-flex;
    align-items: center;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    box-sizing: border-box;
    background: #fff;
    .label-text {
      flex-shrink: 0;
      padding-left: 10px;
    }
  }
  ::v-deep .el-date-editor .el-range-separator {
    width: 10%;
  }
  ::v-deep .el-input__inner {
    border: none;
    height: 34px;
  }
</style>
