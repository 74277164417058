import Vue from 'vue'
import Vuex from 'vuex'
import storage from '../js/common/storage'

Vue.use(Vuex)

const homeRouter = 'business' // 设置登陆后进入的页面-首页默认页，tabs上固定显示，不可删除

export default new Vuex.Store({
  state: {
    loading: false, // 页面加载状态
    token: {
      token: storage.get('token') ? storage.get('token').token : '',
      expire_in: storage.get('token') ? storage.get('token').expire_in : 0
    },
    user_info: storage.get('user_info') ? storage.get('user_info') : '', // 用户信息
    platform_list: storage.get('platform_list') ? storage.get('platform_list') : '', // 所有企业信息
    cur_platform: storage.get('cur_platform') ? storage.get('cur_platform') : '', // 当前企业信息
    platform_id: storage.get('platform_id') ? storage.get('platform_id') : '', // 平台id
    BaseURL_vue: `${window.location.protocol}//${window.location.host}/`, // 全局地址变量
    webViewPath: '/pages/public/webView/webView', // webview小程序内地址
    userAuth: storage.get('userAuth') ? storage.get('userAuth') : [], // 用户操作权限
    menuAuth: storage.get('menuAuth') ? storage.get('menuAuth') : [], // 菜单权限
    visitedViews: [], // 头部tabs
    cachedViews: [homeRouter], // 缓存的组件
    homeRouter: homeRouter,
    size: 'medium', // 设置元素大小  medium/small/mini
    sidebarOpened: storage.get('sidebarOpened') ? storage.get('sidebarOpened') : 1, // 侧边菜单栏是否展开
    header: { // 上传图片头部
      Accept: 'application/json',
      Authorization: 'Bearer ' + (storage.get('token') ? storage.get('token').token : '')
    },
    commonParams: { // 请求公用参数
      // platform_id: storage.get('platform_id') ? storage.get('platform_id') : ''
    },
    project: {
      nameZh: '飞特必吃清单',
      nameEn: 'aokeappletcms',
    }
  },
  mutations: {
    start_loading(state) {
      state.loading = true
    },
    end_loading(state) {
      state.loading = false
    },
    set_token: (state, token) => {
      state.token.token = token
      state.token.expire_in = (new Date() - 1) + 7*24*60*60*1000 // 7天有效期
      state.header.Authorization = 'Bearer ' + token
      storage.set('token', state.token, state.token.expire_in)
    },
    del_token: state => {
      state.token.token = ''
      state.token.expire_in = 0
      state.header.Authorization = 'Bearer ' + ''
      storage.remove('token')
    },
    set_user_info(state, data) {
      state.user_info = data
      storage.set('user_info', data)
    },
    del_user_info(state) {
      state.user_info = ''
      storage.remove('user_info')
    },
    set_platform_list(state, data) {
      state.platform_list = data
      storage.set('platform_list', data)
    },
    del_platform_list(state) {
      state.platform_list = ''
      storage.remove('platform_list')
    },
    set_cur_platform(state, data) {
      state.cur_platform = data
      storage.set('cur_platform', data)
      this.commit('set_platform_id', data.platform_id)
    },
    del_cur_platform(state) {
      state.cur_platform = ''
      storage.remove('cur_platform')
    },
    set_platform_id(state, platform_id) {
      state.platform_id = platform_id
      state.commonParams.platform_id = platform_id
      storage.set('platform_id', platform_id)
    },
    del_platform_id(state) {
      state.platform_id = ''
      state.commonParams.platform_id = ''
      storage.remove('platform_id')
    },
    set_user_auth(state, data) {
      state.userAuth = []
      state.menuAuth = []
      setTimeout(() => {
        state.userAuth = data.operate_auth
        state.menuAuth = data.menu_auth
        storage.set('userAuth', state.userAuth)
        storage.set('menuAuth', state.menuAuth)
      }, 100)
    },
    del_user_auth(state) {
      state.userAuth = []
      state.menuAuth = []
      storage.remove('userAuth')
      storage.remove('menuAuth')
    },
    add_view(state, view) {
      this.commit('add_visited_view', view)
      this.commit('add_cached_view', view)
    },
    add_visited_view(state, view) {
      if (state.visitedViews.some(v => v.path === view.path)) return
      if (!view.meta || !view.meta.title) return
      state.visitedViews.push(
        Object.assign({}, view, {
          title: view.meta.title || 'no-name'
        })
      )
    },
    add_cached_view(state, view) {
      if (state.cachedViews.includes(view.name)) return
      if (!view.meta.noCache) {
        state.cachedViews.push(view.name)
      }
    },
    del_view(state, view) {
      return new Promise(resolve => {
        this.commit('del_visited_view', view)
        this.commit('del_cached_view', view)
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews],
        })
      })
    },
    del_visited_view(state, view) {
      for (const [i, v] of state.visitedViews.entries()) {
        if (v.path === view.path) {
          state.visitedViews.splice(i, 1)
          break
        }
      }
    },
    del_cached_view(state, view) {
      const index = state.cachedViews.indexOf(view.name)
      index > -1 && state.cachedViews.splice(index, 1)
    },
    del_others_views(state, view) {
      return new Promise(resolve => {
        this.commit('del_others_visited_views', view)
        this.commit('del_others_cached_views', view)
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews]
        })
      })
    },
    del_others_visited_views(state, view) {
      state.visitedViews = state.visitedViews.filter(v => {
        return v.meta.affix || v.path === view.path
      })
    },
    del_others_cached_views(state, view) {
      const index = state.cachedViews.indexOf(view.name)
      if (index > -1) {
        state.cachedViews = state.cachedViews.slice(index, index + 1)
      } else {
        // if index = -1, there is no cached tags
        state.cachedViews = [state.homeRouter]
      }
    },
    del_all_views(state, view) {
      return new Promise(resolve => {
        this.commit('del_all_visited_views', view)
        this.commit('del_all_cached_views', view)
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews],
        })
      })
    },
    del_all_visited_views(state) {
      // keep affix tags
      state.visitedViews = state.visitedViews.filter(tag => tag.meta.affix)
    },
    del_all_cached_views(state) {
      state.cachedViews = [state.homeRouter]
    },
    update_visited_view(state, view) {
      for (let v of state.visitedViews) {
        if (v.path === view.path) {
          v = Object.assign(v, view)
          break
        }
      }
    },
    toggle_side_bar(state) {
      state.sidebarOpened = state.sidebarOpened ? 0 : 1
      storage.set('sidebarOpened', state.sidebarOpened)
    },
    logout(state) {
      // 退出清除token，用户信息
      this.commit('del_token')
      this.commit('del_user_info')
      this.commit('del_platform_list')
      this.commit('del_cur_platform')
      this.commit('del_platform_id')
      this.commit('del_all_views') // 清除头部tabs
    }
  },
  actions: {
    delView({ dispatch, state }, view) {
      return new Promise(resolve => {
        dispatch('delVisitedView', view)
        dispatch('delCachedView', view)
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews]
        })
      })
    },
    delVisitedView({ commit, state }, view) {
      return new Promise(resolve => {
        commit('del_visited_view', view)
        resolve([...state.visitedViews])
      })
    },
    delCachedView({ commit, state }, view) {
      return new Promise(resolve => {
        commit('del_cached_view', view)
        resolve([...state.cachedViews])
      })
    },
  },
  modules: {}
})
