import store from '../../store'
import axios from 'axios'
import {errorResponse, responseRoute} from './message'
import deepCopy from '../function/deepCopy'

export const userApi = { // 用户访问地址
  local: '/testapi/api/admin',
  master: '/api/admin'
}

/**
 * 获取对应的api地址
 * @returns {string}
 */
export const getApiUrl = (request) => {
  if (!request) request = deepCopy(userApi)
  const hostArr = ['localhost', '192.168.10.124']
  if (hostArr.includes(document.domain)) {
    return request.local
  } else {
    return request.master
  }
}

/**
 * 请求是否显示加载提示
 * @param url 请求地址
 */
export const saveLoad = (url) => {
  let need = []
  if (!need.includes(url)) {
    store.commit('start_loading') // 打开请求的加载
  }
}

/**
 * 打印请求参数
 */
export const printParams = (data) => {
  let url = data.url
  // 不显示打印信息的接口
  let need = []
  if (!need.includes(url)) {
    console.log(`${url}--postData-->`, data.data)
  }
}

/**
 * 打印响应参数
 */
export const printResponse = (data) => {
  let url = data.config.url
  // 不显示打印信息的接口
  let need = []
  if (!need.includes(url)) {
    console.log(`${url}--res-->`, data.data)
  }
}

/**
 * 更新token数据
 */
export const updateToken = (res) => {
  if (res.headers.token && (res.headers.token !== store.state.token.token)) {
    store.commit('set_token', res.headers.token)
  }
}

/**
 * axios请求基本参数配置
 */
axios.defaults.timeout = 60000 // 请求超时时间/配置响应时间 单位 ms
axios.defaults.headers['Accept'] = 'application/json' // 指定服务器响应的数据类型
axios.defaults.headers.post['Content-Type'] = 'application/json' // 请求体内容的数据类型，发送数据至服务器内容编码类型

/**
 * 添加请求拦截器
 * POST 请求之前，序列化参数
 */
axios.interceptors.request.use((config) => {
  // POST 请求之前，执行的操作
  saveLoad(config.url)
  // printParams(config)
  config.headers['Authorization'] = 'Bearer ' + store.state.token.token
  if (config.method === 'post') {
    config.data = JSON.stringify(config.data)
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

/**
 * 添加响应拦截器
 */
axios.interceptors.response.use(async(res) => {
  await store.commit('end_loading') // 关闭请求的加载
  responseRoute(res.data)
  // printResponse(res)
  updateToken(res)
  return Promise.resolve(res.data)
}, async(error) => {
  await store.commit('end_loading') // 关闭请求的加载
  errorResponse(error)
  return Promise.reject(error)
})

/**
 * @param url
 * @param params
 * @param base
 * @param post  true:post请求，false:get请求
 * @return {Promise<unknown>}
 */
export const $axios = (url, params = {}, base, post = true) => {
  return new Promise((resolve, reject) => {
    // 每个请求都带公共参数
    params = {
      ...store.state.commonParams,
      ...params,
    }
    if (!base) base = deepCopy(userApi)
    axios.defaults.baseURL = getApiUrl(base)
    let mode = post ? 'post' : 'get'
    let data = post ? params : {params: params}
    axios[mode](url, data).then(response => {
      resolve(response)
    }, err => {
      reject(err)
    }).catch((error) => {
      reject(error)
    })
  })
}

export default {
  getApiUrl,
  $axios,
}
