<template>
  <el-tooltip
    :content="placeholder"
    :placement="placement"
    :enterable="enterable"
    :effect="effect"
    :style="`margin-bottom: ${margin_bottom}; margin-right: ${margin_right}; width: ${width}`"
  >
    <el-input
      v-model="value"
      :disabled="disabled"
      :size="globalSize"
      :clearable="clearable"
      :placeholder="placeholder"
      class="search-input"
      @keyup.enter.native="search"
    >
      <template slot="prepend">{{label_name}}</template>
    </el-input>
  </el-tooltip>
</template>

<script>

  export default {
    name: 'searchInput',
    props: {
      keyword: {required: true, type: String, default: ''},
      placeholder: {type: String, default: '单号、关键字'},
      label_name: {type: String, default: '关键字'},
      disabled: {type: Boolean, default: false},
      clearable: {type: Boolean, default: true},
      width: {type: String, default: '185px'},
      margin_right: {type: String, default: '8px'},
      margin_bottom: {type: String, default: '8px'},
      placement: {type: String, default: 'top'},
      effect: {type: String, default: 'dark'},
      enterable: {type: Boolean, default: false}
    },
    data() {
      return {
        value: ''
      }
    },
    computed: {
      globalSize() {
        return this.$store.state.size
      }
    },
    methods: {
      search() {
        this.$emit('search')
      }
    },
    watch: {
      value(val) {
        this.$emit('update:keyword', val)
      },
      keyword(val) {
        this.value = this.deepCopy(val)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .search-input {
    border-left: 0!important;
  }
  ::v-deep .el-input-group__prepend {
    background: #fff;
    padding-left: 10px;
    padding-right: 0;
    color: $font-c-1;
  }
  ::v-deep .el-input__inner {
    border-left: 0;
  }
  ::v-deep .el-input__inner:hover {
    border-color: #DCDFE6;
  }
  ::v-deep .el-input__inner:focus {
    border-color: #DCDFE6;
  }
</style>
