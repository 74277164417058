<template>
  <el-button
    :disabled="disabled"
    :size="globalSize"
    type="primary"
    v-if="checkRule(rules)"
    @click="add"
    class="add-button"
    :style="`margin-bottom: ${margin_bottom}; margin-right: ${margin_right}`"
  >
    <i class="el-icon-plus add-icon"/>{{name}}
  </el-button>
</template>

<script>
  import { checkRule } from '../../js/common/util'

  export default {
    name: 'addButton',
    props: {
      rules: {type: Array, default: () => {return []}},
      disabled: {type: Boolean, default: false},
      margin_bottom: {type: String, default: '8px'},
      margin_right: {type: String, default: '8px'},
      name: {type: String, default: '添加'},
    },
    computed: {
      globalSize() {
        return this.$store.state.size
      }
    },
    methods: {
      checkRule,
      add() {
        this.$emit('add')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .add-button {
    margin-left: 0;
  }

  .add-icon {
    font-weight: 600;
    padding-right: 3px;
  }
</style>
