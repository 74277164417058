<template>
  <div :align="align" slot="footer" style="padding-right: 10px">
    <el-button :size="globalSize" @click="close" :type="cancel_type" v-if="!saving">{{cancel}}</el-button>
    <el-button :size="globalSize" @click="save" :type="submit_type" v-if="!saving">{{submit}}</el-button>
    <i class="el-icon-loading color-primary" style="font-size: 25px" v-if="saving"/>
  </div>
</template>

<script>
  export default {
    name: 'submitButton',
    props: {
      align: {type: String, default: 'center'},
      cancel: {type: String, default: '取消'},
      submit: {type: String, default: '提交'},
      saving: {type: Boolean, default: false},
      cancel_type: {type: String, default: 'default'},
      submit_type: {type: String, default: 'primary'}
    },
    computed: {
      globalSize() {
        return this.$store.state.size
      }
    },
    methods: {
      close() {
        this.$emit('close')
      },
      save() {
        this.$emit('save')
      }
    }
  }
</script>
