<template>
  <div>
    <el-dialog
      :before-close="close"
      :title="title"
      :visible.sync="dialog"
      @opened="open"
      append-to-body
      top="8vh"
      class="small-dialog change-password"
      width="400px"
      :close-on-click-modal="false">
      <el-form :model="form" ref="form" class="base-form" :label-width="label_width" :show-message="false">
        <el-form-item prop="old_password" label="旧密码" required>
          <el-input :type="flag == false ? 'password' : 'text'" v-model="form.old_password" placeholder="请输入旧密码" autocomplete="off">
            <div slot="suffix" class="suffix-icon">
              <span :class="`iconfont ${flag ? 'icon-open_eye view-icon' : 'icon-close_eye close-eye-icon'}`" @click="changeIconState('flag')"/>
            </div>
          </el-input>
        </el-form-item>
        <el-form-item prop="new_password" label="新密码" required>
          <el-input :type="flag2 == false ? 'password' : 'text'" v-model="form.new_password" placeholder="请输入新密码" autocomplete="off">
            <div slot="suffix" class="suffix-icon">
              <span :class="`iconfont ${flag2 ? 'icon-open_eye view-icon' : 'icon-close_eye close-eye-icon'}`" @click="changeIconState('flag2')"/>
            </div>
          </el-input>
        </el-form-item>
        <el-form-item prop="confirm_password" label="确认新密码" required>
          <el-input :type="flag3 == false ? 'password' : 'text'" v-model="form.confirm_password" placeholder="请输入确认新密码" autocomplete="off">
            <div slot="suffix" class="suffix-icon">
              <span :class="`iconfont ${flag3 ? 'icon-open_eye view-icon' : 'icon-close_eye close-eye-icon'}`" @click="changeIconState('flag3')"/>
            </div>
          </el-input>
        </el-form-item>
      </el-form>
      <submit-button @close="close" @save="changePsw"/>
    </el-dialog>
  </div>
</template>

<script>
  import md5 from 'js-md5'

  export default {
    name: 'changePass',
    data() {
      return {
        title: '修改密码',
        dialog: false,
        label_width: '100px',
        form: {
          old_password: '',
          new_password: '',
          confirm_password: '',
        },
        flag: false,
        flag2: false,
        flag3: false,
      }
    },
    methods: {
      open() {},
      close() {
        this.$refs.form.resetFields()
        this.flag = false
        this.flag2 = false
        this.flag3 = false
        this.dialog = false
      },
      changeIconState(name) {
        this[name] = !this[name]
      },
      changePsw() {
        var that = this
        if (!that.form.old_password) return that.$msg.topMessage('请输入旧密码！')
        if (!that.form.new_password) return that.$msg.topMessage('请输入新密码！')
        if (!that.form.confirm_password) return that.$msg.topMessage('请输入确认新密码！')
        if (that.form.new_password != that.form.confirm_password) return that.$msg.topMessage('新密码与确认新密码不一致！')
        var url = '/user/change_password'
        var postData = {
          old_password: md5(that.form.old_password),
          new_password: md5(that.form.new_password),
        }
        that.$axios(url, postData).then(res => {
          if (res.status_code == 200 || res.status_code == 201) {
            that.$msg.topMessage('修改密码成功！', 'success', () => {
              that.close()
              // that.$store.commit('logout')
              // that.$router.push({ name: 'login' })
            })
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .change-password {
    .suffix-icon {
      width: 24px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .view-icon {
        font-size: 16px;
      }
      .close-eye-icon {
        width: 20px;
      }
    }
  }
</style>
