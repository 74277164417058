<template>
  <el-button
    :disabled="disabled || !checkRule(rules)"
    :size="globalSize"
    :type="type"
    @click="clickBtn"
    class="text-button"
    :style="`margin-bottom: ${margin_bottom}; margin-left: ${margin_left}; margin-right: ${margin_right}`"
  >
    {{name}}
  </el-button>
</template>

<script>
  import { checkRule } from '../../js/common/util'

  export default {
    name: 'textButton',
    props: {
      rules: {type: Array, default: () => {return []}},
      disabled: {type: Boolean, default: false},
      name: {type: String, default: '查询'},
      type: {type: String, default: 'primary'}, // primary/success/warning/danger/info/text
      margin_bottom: {type: String, default: '8px'},
      margin_left: {type: String, default: '0'},
      margin_right: {type: String, default: '8px'},
    },
    computed: {
      globalSize() {
        return this.$store.state.size
      }
    },
    methods: {
      checkRule,
      clickBtn() {
        this.$emit('clickBtn')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .text-button {
    padding-left: 10px;
    padding-right: 10px;
  }
</style>
