<template>
  <div class="app-wrapper">
    <nav-bar />
    <side-bar class="sidebar-container" />
    <div class="main-container">
      <tabs-view />
      <app-main class="app-main" />
    </div>
  </div>
</template>

<script>
import appMain from './components/appMain'
import sideBar from './components/sideBar/sideBar'
import navBar from './components/navBar/navBar'
import tabsView from './components/tabsView/tabsView'

export default {
  name: 'layout',
  components: {
    appMain,
    sideBar,
    navBar,
    tabsView
  }
}
</script>

<style lang="scss" scoped>
  .main-container{
    margin-left: $sideBarWidth;
    background: $mainContainerBg;
  }
  .app-main {
    height: calc(100vh - #{$navHeight} - #{$tabsViewHeight});
    width: 100%;
    padding: 6px;
    text-align: left;
    position: relative;
    overflow-y: auto;
  }
</style>
