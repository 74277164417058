import router from './router'
import store from './store'
import {confirmBox} from './js/common/message'

const whiteList = ['login'] // 路由白名单

router.beforeEach(async(to, from, next) => {
  // set page title
  let queryToken = to.query.token
  document.title = to.meta && to.meta.title ? to.meta.title : `${store.state.project.nameZh}`
  if (queryToken) {
    await store.commit('set_token', queryToken)
  }
  if (to.query.platform_id) {
    await store.commit('set_platform_id', to.query.platform_id)
  }
  // 判断是否有权限
  let token = store.state.token
  let hasToken = token.token !== '' && token.expire_in > new Date().getTime()
  if (hasToken) {
    if (to.path === '/' || to.path === '/login' || !to.name) {
      next({ name: store.state.homeRouter })
    } else {
      next()
    }
  } else {
    await store.commit('logout')
    if (whiteList.indexOf(to.name) !== -1) {
      next()
    } else {
      await confirmBox('请先登录！', '提示', () => {
        next({ path: '/login' })
      })
    }
  }
})
