import Vue from 'vue'
import message from './common/message'
import storage from './common/storage'
import { $axios } from './common/axios'
import { setField, clearField } from './common/util'
import deepCopy from './function/deepCopy'
import autoTableHeight from './function/autoTableHeight'
import './function/tableFit' // vue自定义指令-el-table列宽根据内容自适应

Vue.prototype.$msg = message // 注册全局消息提醒方法
Vue.prototype.$storage = storage // 注册浏览器的储存数据方法
Vue.prototype.$axios = $axios // 请求接口
Vue.prototype.setField = setField // 字段赋值
Vue.prototype.clearField = clearField // 清除字段
Vue.prototype.deepCopy = deepCopy // 数据深拷贝
Vue.prototype.autoTableHeight = autoTableHeight // 自动调整表格高度
