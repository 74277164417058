<template>
  <div class="tags-view-container" id="tags-view-container">
    <scroll-pane class="tags-view-wrapper" ref="scrollPane">
      <router-link
        :class="isActive(tag) ? 'active' : ''"
        :key="tag.path"
        :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
        @click.middle.native="!isAffix(tag) ? closeSelectedTag(tag) : ''"
        class="tags-view-item"
        ref="tag"
        tag="span"
        v-for="tag in visitedViews">
<!--        <i :class="tag.meta.icon" class="tab-icon"/>-->
        {{ tag.title }}
        <span @click.prevent.stop="closeSelectedTag(tag)" class="el-icon-close"/>
      </router-link>
    </scroll-pane>
  </div>
</template>

<script>
  import scrollPane from './scrollPane'
  import path from 'path'

  export default {
    name: 'TabsView',
    components: {
      scrollPane
    },
    data() {
      return {
        top: 0,
        left: 0,
        selectedTag: {},
        affixTags: []
      }
    },
    computed: {
      visitedViews() {
        return this.$store.state.visitedViews
      },
      homeRouter() {
        return this.$store.state.homeRouter
      },
      routes() {
        return this.$router.options.routes
      }
    },
    watch: {
      $route() {
        this.addTags()
        this.moveToCurrentTag()
      }
    },
    mounted() {
      this.initTags()
      this.addTags()
    },
    methods: {
      isActive(route) {
        return route.path === this.$route.path
      },
      isAffix(tag) {
        return tag.meta && tag.meta.affix
      },
      filterAffixTags(routes, basePath = '/') {
        let tags = []
        routes.forEach(route => {
          if (route.meta && route.meta.affix) {
            const tagPath = path.resolve(basePath, route.path)
            tags.push({
              fullPath: tagPath,
              path: tagPath,
              name: route.name,
              meta: { ...route.meta }
            })
          }
          if (route.children) {
            const tempTags = this.filterAffixTags(route.children, route.path)
            if (tempTags.length >= 1) {
              tags = [...tags, ...tempTags]
            }
          }
        })
        return tags
      },
      initTags() {
        const affixTags = this.affixTags = this.filterAffixTags(this.routes)
        for (const tag of affixTags) {
          // Must have tag name
          if (tag.name) {
            this.$store.commit('add_visited_view', tag)
          }
        }
      },
      addTags() {
        const { name } = this.$route
        if (name) {
          this.$store.commit('add_view', this.$route)
        }
        return false
      },
      // 移动到当前活动的tab
      moveToCurrentTag() {
        const tags = this.$refs.tag
        this.$nextTick(() => {
          for (const tag of tags) {
            if (tag.to.path === this.$route.path) {
              this.$refs.scrollPane.moveToTarget(tag)
              // when query is different then update
              if (tag.to.fullPath !== this.$route.fullPath) {
                this.$store.commit('update_visited_view', this.$route)
              }
              break
            }
          }
        })
      },
      // 关闭tab
      closeSelectedTag(view) {
        if (this.isAffix(view)) {
          return this.$msg.topMessage('第一页不可关闭！')
        }
        this.$store.dispatch('delView', view).then(({ visitedViews }) => {
          if (this.isActive(view)) {
            this.toLastView(visitedViews, view)
          }
        })
      },
      // 关闭当前活动active的tab时，设置最后一个tab的状态为active
      toLastView(visitedViews, view) {
        const latestView = visitedViews.slice(-1)[0]
        if (latestView) {
          this.$router.push(latestView.fullPath)
        } else {
          // now the default is to redirect to the home page if there is no tags-view,
          // you can adjust it according to your needs.
          if (view.name === this.homeRouter) {
            // to reload home page
            this.$router.replace({ path: '/redirect' + view.fullPath })
          } else {
            this.$router.push('/')
          }
        }
      }
    }
  }
</script>

<style lang="scss" src="./tabsView.scss" scoped/>
