import message from './message'

const hostArr = ['localhost', '192.168.1.121']
const domain = 'aokeappletcms_'
const prefix = hostArr.includes(document.domain) ? `local_${domain}` : domain

// sessionStorage会话存储字段
const sessionArr = []

/**
 * 定时储存前端数据
 * @param k 数据id
 * @param v 数据值
 * @param t 储存时间 0不限制时间
 */
export const set = (k, v, t = 0) => {
  const session = sessionArr.includes(k)
  k = prefix + k
  try {
    if (!localStorage) return false
    let exp = (new Date() - 1) + t * 1000
    if (t === 0) exp = 0
    let data = { val: v, exp: exp }
    if (session) sessionStorage.setItem(k, JSON.stringify(data))
    else localStorage.setItem(k, JSON.stringify(data))
  } catch (e) {
    message.message('存储失败', '', 'fail')
  }
}

/**
 * 获取储存在前端的数据
 * @param k 数据id
 */
export const get = (k) => {
  const session = sessionArr.includes(k)
  k = prefix + k
  try {
    if (!localStorage) return false
    let data = ''
    if (session) data = JSON.parse(sessionStorage.getItem(k))
    else data = JSON.parse(localStorage.getItem(k))
    if (!data) return null
    else return data.val
  } catch (e) {
    remove(k)
    return null
  }
}

/**
 * 获取数据到期时间
 * @param k
 * @returns {*}
 */
export const getExp = (k) => {
  k = prefix + k
  try {
    if (!localStorage) return false
    let data = JSON.parse(localStorage.getItem(k))
    if (!data) return null
    return data.exp
  } catch (e) {
    remove(k)
    return null
  }
}

/**
 * 移除前端储存的数据
 * @param k 数据id
 * @returns {boolean}
 */
export const remove = (k) => {
  k = prefix + k
  if (!localStorage) return false
  localStorage.removeItem(k)
  sessionStorage.removeItem(k)
}

/**
 * 清除所有前端储存的数据
 * @returns {boolean}
 */
export const clear = () => {
  if (!localStorage) return false
  localStorage.clear()
  sessionStorage.clear()
}

export default {
  set,
  get,
  getExp,
  remove,
  clear,
}
