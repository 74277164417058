<template>
  <div
    class="select-list-box"
    :style="`margin-bottom: ${margin_bottom}; margin-right: ${margin_right}; margin-left: ${margin_left}; width: ${select_width}`"
    :disabled="disabled"
  >
    <span v-if="show_label" class="label-text">{{label_name}}</span>
    <el-select
      v-model="id"
      :disabled="disabled"
      :multiple="multiple"
      :multiple-limit="limit"
      :clearable="clearable"
      @clear="clear"
      @focus="focus"
      @blur="blur"
      @change="change"
      :collapse-tags="collapseTags"
      :placeholder="placeholder"
      :size="globalSize"
      value=""
      filterable
      remote
      :remote-method="remoteMethod"
      style="width: 100%"
    >
      <el-option v-if="show_all" :label="label_all" :value="value_all">
        <span style="float: left">{{label_all}}</span>
      </el-option>
      <el-option v-for="(item, index) in list" :key="index" :label="item[label]" :value="item[value]">
        <span style="float: left">{{ item[label] }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
  export default {
    name: 'selectList',
    data() {
      return {
        id: '',
        list: [],
        keyword: '',
      }
    },
    props: {
      select_list: {type: Array, default: () => {return []}},
      label_name: {type: String, default: ''},
      key_name: {type: String, default: 'search_key'},
      key_init: {type: String, default: ''},
      url: {type: String, default: ''},
      select_id: { required: true },
      disabled: {type: Boolean, default: false},
      clearable: {type: Boolean, default: true},
      placeholder: {type: String, default: '输入名称快捷筛选'},
      select_width: {type: String, default: '170px'},
      margin_left: {type: String, default: '0'},
      margin_right: {type: String, default: '8px'},
      margin_bottom: {type: String, default: '8px'},
      show_all: {type: Boolean, default: false}, // 是否显示全部的下拉选项
      label_all: {type: String, default: '全部'},
      value_all: {type: String, default: ''}, // 全部选项的值
      label: {type: String, default: 'name'},
      value: {type: String, default: 'id'},
      multiple: {type: Boolean, default: false}, // 是否可多选
      limit: {type: Number, default: 0}, // 多选时用户最多可以选择的项目数，为 0 则不限制
      collapseTags: {type: Boolean, default: true}, // 多选时是否将选中值按文字的形式展示
      post: {type: Object, default: () => { return {} }}, // 请求参数
      show_label: {type: Boolean, default: true},
    },
    computed: {
      globalSize() {
        return this.$store.state.size
      }
    },
    deactivated() {
      this.keyword = ''
    },
    methods: {
      init() {
        // 再次编辑时没有该选项则清空数据
        let flag = this.list.some(item => {
          let hasVal = this.multiple ? this.select_id.includes(item[this.value]) : item[this.value] == this.select_id
          return hasVal
        })
        if (flag) {
          this.id = this.deepCopy(this.select_id)
        } else if (this.key_init) {
          this.keyword = this.deepCopy(this.key_init)
          this.$emit('update:key_init', '')
          this.getList()
        } else {
          this.clear()
        }
      },
      getList() {
        let that = this
        let postData = that.deepCopy(that.post)
        postData = Object.assign(postData, {[that.key_name]: that.keyword})
        that.$axios(that.url, postData).then((res) => {
          if (res.status_code == 200 || res.status_code == 201) {
            that.list = res.data
            that.init()
          }
        })
      },
      change() {
        let that = this
        var obj = that.list.find(item => {
          return item[that.value] == that.id
        })
        var data = obj ? that.deepCopy(obj) : ''
        that.$emit('update:select_id', that.id)
        that.$emit('change', that.deepCopy(that.id), data, that.deepCopy(that.list))
      },
      clear() {
        this.id = ''
        this.keyword = ''
        this.$emit('update:key_init', '')
        this.change()
      },
      focus() {
        if (this.url && !this.list.length) this.getList()
      },
      blur() {
        if (!this.id) {
          this.keyword = ''
        }
      },
      remoteMethod(query) {
        this.keyword = query
        this.getList()
      }
    },
    watch: {
      select_id() {
        // this.init()
      },
      select_list: {
        handler(val) {
          if (!this.url) {
            this.list = val
          }
        },
        immediate: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .select-list-box {
    display: inline-flex;
    align-items: center;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    box-sizing: border-box;
    background: #fff;
    .label-text {
      flex-shrink: 0;
      padding-left: 10px;
    }
    ::v-deep .el-input__inner {
      border: none;
      height: 34px;
    }
  }
</style>
