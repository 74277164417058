<template>
  <div
    class="time-range"
    :style="`margin-bottom: ${margin_bottom}; margin-right: ${margin_right}; width: ${width}`"
  >
    <span v-if="show_label" class="label-text">{{label_name}}</span>
<!--    <i v-if="loading" class="el-icon-loading color-primary" style="font-size: 20px; vertical-align: middle"/>-->
    <el-radio-group v-if="radio" v-model="time" :size="globalSize">
      <el-radio-button v-if="ranges.indexOf(1) > -1" :label="1">上月</el-radio-button>
      <el-radio-button v-if="ranges.indexOf(1) > -1" :label="7">下月</el-radio-button>
<!--      <el-radio-button v-if="ranges.indexOf(2) > -1" :label="2">上周</el-radio-button>-->
      <el-radio-button v-if="ranges.indexOf(3) > -1" :label="3">今日</el-radio-button>
<!--      <el-radio-button v-if="ranges.indexOf(4) > -1" :label="4">本周</el-radio-button>-->
      <el-radio-button v-if="ranges.indexOf(5) > -1" :label="5">本月</el-radio-button>
      <el-radio-button v-if="ranges.indexOf(6) > -1" :label="6">今年</el-radio-button>
    </el-radio-group>
    <el-date-picker
            v-if="showPicker === true"
            :clearable="clearable"
            v-model="dateRange"
            :size="globalSize"
            :type="timeType"
            range-separator="至"
            start-placeholder="开始时间"
            :picker-options="pickerOptions"
            @change="changeDate()"
            end-placeholder="结束时间"
    >
    </el-date-picker>
  </div>
</template>
<script>
  import { RadioButton, RadioGroup } from 'element-ui'
  import extend from '../../js/function/extend'
  import { formatDay, getLastMonth, getMonth, getYear, formatDate, getTimestamp, formatTime } from '../../js/common/time'

  export default {
    name: 'timeRange',
    components: {
      [RadioButton.name]: RadioButton,
      [RadioGroup.name]: RadioGroup,
    },
    props: {
      select_time: {type: [String, Array], default: ''},
      width: {type: String, default: '280px'},
      margin_right: {type: String, default: '8px'},
      margin_bottom: {type: String, default: '8px'},
      radio: {type: Boolean, default: false},
      clearable: {type: Boolean, default: false},
      shortcuts: {type: Boolean, default: true}, // 是否显示快捷选项
      showPicker: {type: Boolean, default: true},
      // pickerOption: {type: Object, default: null},
      pickerOptionType: {type: Number, default: 0}, // 默认设置不限制时间，1-当前日期以及之后可选、2-当前日期之后可选、3-当前日期以及之前可选、4-当前日期之前可选
      ranges: {
        type: Array,
        default: () => {
          return [1, 2, 3, 4, 5, 6, 7]
        }
      },
      show_label: {type: Boolean, default: true},
      label_name: {type: String, default: '日期'},
      timeType: {type: String, default: 'daterange'},
    },
    data() {
      let vm = this
      return {
        time: 5,
        dateRange: [],
        options: {
          shortcuts: [
            {
              text: '上月',
              onClick(picker) {
                let date = vm.dateRange[0] ? vm.dateRange[0] : formatDate().date
                let time = formatDate(date)
                let year = getYear(date)
                let month = getLastMonth(date)
                if (month == 0) {
                  month = 12
                  year = year - 1
                }
                let start = year + '-' + month + '-01 00:00:00'
                let endDay = time.Y + time.M + 1
                let timestamp = getTimestamp(endDay) - 1
                let end = formatDay(timestamp) + '23:59:59'
                picker.$emit('pick', [start, end])
              }
            },
            {
              text: '下月',
              onClick(picker) {
                let date = vm.dateRange[0] ? vm.dateRange[0] : formatDate().date
                let year = getYear(date)
                let month = getMonth(date)
                let year1, month1, year2, month2
                month1 = Number(month) + 1 < 10 ? '0' + (Number(month) + 1) : Number(month) + 1
                month2 = Number(month) + 2 < 10 ? '0' + (Number(month) + 2) : Number(month) + 2
                year1 = year2 = year
                if (month1 == 12) {
                  month2 = '01'
                  year2 = year + 1
                }
                if (month1 == 13) {
                  month1 = '01'
                  month2 = '02'
                  year1 = year + 1
                  year2 = year + 1
                }
                let start = year1 + '-' + month1 + '-01 00:00:00'
                let endDay = year2 + '-' + month2 + '-' + 1
                let timestamp = getTimestamp(endDay) - 1
                let end = formatDay(timestamp) + '23:59:59'
                picker.$emit('pick', [start, end])
              }
            },
            // {
            //   text: '上周',
            //   onClick(picker) {
            //     let now = new Date()
            //     let startDay = now.getTime() - ((now.getDay() === 0 ? 7 + 6 : now.getDay() + 6) * 86400 * 1000)
            //     let endDay = now.getTime() - ((now.getDay() === 0 ? 7 : now.getDay()) * 86400 * 1000)
            //     let start = formatDay(startDay)
            //     let end = formatDay(endDay)
            //     picker.$emit('pick', [start, end])
            //   }
            // },
            {
              text: '今日',
              onClick(picker) {
                let start = formatDay() + '00:00:00'
                let end = formatDay() + '23:59:59'
                picker.$emit('pick', [start, end])
              }
            },
            // {
            //   text: '本周',
            //   onClick(picker) {
            //     let now = new Date()
            //     let startDay = now.getTime() - ((now.getDay() === 0 ? 7 - 1 : now.getDay() - 1) * 86400 * 1000)
            //     let start = formatDay(startDay)
            //     let end = formatDay()
            //     picker.$emit('pick', [start, end])
            //   }
            // },
            {
              text: '本月',
              onClick(picker) {
                let time = formatDate()
                let start = time.Y + time.M + '01 00:00:00'
                let end = time.Y + time.M + time.D + '23:59:59'
                picker.$emit('pick', [start, end])
              }
            },
            {
              text: '今年',
              onClick(picker) {
                let time = formatDate()
                let start = time.Y + '01-01 00:00:00'
                let end = time.Y + time.M + time.D + '23:59:59'
                picker.$emit('pick', [start, end])
              }
            }
          ]
        },
        dates: [
          { // 禁选今天及之前的日期
            disabledDate(time) {
              return time.getTime() <= Date.now() - 8.64e7
            }
          },
          { // 禁选今天之前的日期
            disabledDate(time) {
              return time.getTime() < Date.now()
            }
          },
          { // 禁选今天及之后的日期
            disabledDate(time) {
              return time.getTime() >= Date.now()
            }
          },
          { // 禁选今天及之后的日期
            disabledDate(time) {
              return time.getTime() > Date.now()
            }
          }
        ]
      }
    },
    computed: {
      globalSize() {
        return this.$store.state.size
      },
      pickerOptions() {
        let option = this.pickerOptionType === 0 ? {} : this.dates[this.pickerOptionType - 1]
        let initShortcuts = {}
        let initOptions = this.shortcuts ? this.options : initShortcuts
        return extend(option, initOptions)
      }
    },
    watch: {
      time() {
        this.generateTime()
      },
      select_time(val) {
        this.dateRange = this.deepCopy(val)
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.generateTime(this.time)
      },
      generateTime(time) {
        if (!time) time = this.time
        switch (time) {
          case 1:
            this.lastMonth()
            break
          case 2:
            this.lastWeek()
            break
          case 3:
            this.today()
            break
          case 4:
            this.week()
            break
          case 5:
            this.month()
            break
          case 6:
            this.year()
            break
        }
        this.changeDate()
      },
      initTime(start, end) {
        if (this.timeType == 'datetimerange') {
          start = start + '00:00:00'
          end = end + '23:59:59'
        }
        this.dateRange = [start, end]
      },
      lastMonth() {
        let time = formatDate()
        let start = time.Y + getLastMonth() + '-01 '
        let endDay = time.Y + time.M + 1
        let timestamp = getTimestamp(endDay) - 1
        let end = formatDay(timestamp)
        this.initTime(start, end)
      },
      lastWeek() {
        let now = new Date()
        let startDay = now.getTime() - ((now.getDay() === 0 ? 7 + 6 : now.getDay() + 6) * 86400 * 1000)
        let endDay = now.getTime() - ((now.getDay() === 0 ? 7 : now.getDay()) * 86400 * 1000)
        let start = formatDay(startDay)
        let end = formatDay(endDay)
        this.initTime(start, end)
      },
      today() {
        let start = formatDay()
        let end = formatDay()
        this.initTime(start, end)
      },
      week() {
        let now = new Date()
        let startDay = now.getTime() - ((now.getDay() === 0 ? 7 - 1 : now.getDay() - 1) * 86400 * 1000)
        let start = formatDay(startDay)
        let end = formatDay()
        this.initTime(start, end)
      },
      month() {
        let year = getYear()
        let month = getMonth()
        let year2 = year, month2 = Number(month) + 1 < 10 ? '0' + (Number(month) + 1) : Number(month) + 1
        if (month == 12) {
          month2 = '01'
          year2 = year + 1
        }
        let start = year + '-' + month + '-01 '
        let endDay = year2 + '-' + month2 + '-01 '
        let timestamp = getTimestamp(endDay) - 1
        let end = formatDay(timestamp)
        this.initTime(start, end)
      },
      year() {
        let time = formatDate()
        let start = time.Y + '01-01 '
        let end = time.Y + time.M + time.D
        this.initTime(start, end)
      },
      changeDate() {
        let range = []
        if (this.dateRange && this.dateRange.length > 0) {
          if (this.timeType == 'datetimerange') {
            range[0] = formatTime(this.dateRange[0])
            range[1] = formatTime(this.dateRange[1])
          } else {
            range[0] = formatDay(this.dateRange[0])
            range[1] = formatDay(this.dateRange[1])
          }
        }
        this.$emit('change', range, this.time)
        this.$emit('update:select_time', range)
      }
    },
  }
</script>

<style lang="scss" scoped>
  .time-range {
    display: inline-flex;
    align-items: center;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    box-sizing: border-box;
    background: #fff;
    .label-text {
      flex-shrink: 0;
      padding-left: 10px;
    }
  }
  ::v-deep .el-date-editor .el-range-separator {
    width: 10%;
  }
  ::v-deep .el-input__inner {
    border: none;
    height: 34px;
  }
</style>
