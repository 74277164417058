import Vue from 'vue'
import addButton from './tool/addButton'
import iconButton from './tool/iconButton'
import textButton from './tool/textButton'
import radioGroup from './tool/radioGroup'
import searchInput from './tool/searchInput'
import selectList from './tool/selectList'
import remoteSelectList from './tool/remoteSelectList'
import monthRange from './tool/monthRange'
import timeRange from './tool/timeRange'
import datePicker from './tool/datePicker'
import pagination from './tool/pagination'
import dropdown from './tool/dropdown'
import printDropdown from './tool/printDropdown'
import submitButton from './tool/submitButton'

Vue.component('add-button', addButton)
Vue.component('icon-button', iconButton)
Vue.component('text-button', textButton)
Vue.component('radio-group', radioGroup)
Vue.component('search-input', searchInput)
Vue.component('select-list', selectList)
Vue.component('remote-select-list', remoteSelectList)
Vue.component('month-range', monthRange)
Vue.component('time-range', timeRange)
Vue.component('date-picker', datePicker)
Vue.component('pagination', pagination)
Vue.component('dropdown', dropdown)
Vue.component('print-dropdown', printDropdown)
Vue.component('submit-button', submitButton)
