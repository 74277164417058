<template>
  <el-radio-group
    :disabled="disabled"
    :size="globalSize"
    @change="change"
    v-model="value"
    v-show="true"
    class="radio-group-box"
    :style="`margin-right: ${margin_right}; width: ${width};`"
  >
    <span v-if="show_label" class="label-text">{{label_name}}</span>
    <el-radio :label="''" v-if="show_all" class="radio-item" :style="item_style">全部</el-radio>
    <el-radio v-for="(item, index) in list" :key="index" :label="item.label" :style="item_style" class="radio-item">{{item.name}}</el-radio>
  </el-radio-group>
</template>

<script>
  import { RadioButton, RadioGroup } from 'element-ui'

  export default {
    name: 'radioGroup',
    components: {
      [RadioGroup.name]: RadioGroup,
      [RadioButton.name]: RadioButton
    },
    props: {
      type: {required: true},
      width: {type: String, default: ''},
      margin_right: {type: String, default: '8px'},
      disabled: {type: Boolean, default: false},
      show_all: {type: Boolean, default: false},
      list: {type: Array, default: () => {return []} },
      label_name: {type: String, default: '查询类型'},
      show_label: {type: Boolean, default: true},
      item_style: {type: String, default: ''},
    },
    data() {
      return {
        value: ''
      }
    },
    computed: {
      globalSize() {
        return this.$store.state.size
      }
    },
    created() {
      this.$nextTick(() => {
        this.value = this.deepCopy(this.type)
      })
    },
    methods: {
      change(type) {
        this.$emit('update:type', type)
        this.$emit('change', type)
      }
    },
    watch: {
      type(val) {
        this.value = this.deepCopy(val)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .radio-group-box {
    display: inline-flex;
    align-items: center;
    height: 36px;
    padding-right: 15px;
    font-size: 14px;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #DCDFE6;
    margin-top: -4px;
    .label-text {
      padding-left: 10px;
      padding-right: 15px;
    }
    .label-text + .radio-item {
      margin-left: 0px;
    }
    .radio-item {
      margin-left: 15px;
      margin-right: 0;
    }
  }
</style>
