import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './permission' // 路由权限控制
import ElementUI from 'element-ui' // 引入elementUI
import 'element-ui/lib/theme-chalk/index.css'
import './styles/global.scss' // 引入global css
import './js/global.js' // 引入全局扩展方法
import './components/components' // 入全局注册组件
//引入tinymce编辑器
import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'

Vue.prototype.$tinymce = tinymce
Vue.use(VueTinymce)

Vue.use(ElementUI, { size: 'medium', zIndex: 3000 })

Vue.config.productionTip = false

/* 路由异常错误处理，尝试解析一个异步组件时发生错误，重新渲染目标页面 */
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    router.replace(targetPath)
  }
})

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
