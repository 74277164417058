<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'app',
    computed: {
      loading() {
        return this.$store.state.loading
      }
    },
    watch: {
      loading(val) {
        if (val) this.$msg.loadings('加载中...', 0)
        else this.$msg.clear()
      }
    }
  }
</script>
