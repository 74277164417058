/** ************************************************ 实用函数 ************************************************ **/
import deepCopy from '../function/deepCopy'
import message from './message'
import store from '../../store'
import axios from 'axios'
import {getApiUrl} from './axios'

/**
 * 字段赋值
 * @param form
 * @param data
 * @returns {*}
 */
export const setField = (form, data) => {
  let need = deepCopy(form)
  for (let k in need) {
    form[k] = ([undefined, ' - ', '-'].indexOf(data[k]) !== -1) ? need[k] : data[k]
  }
  return form
}

/**
 * 清除字段
 * @param form
 * @param data
 * @returns {*}
 */
export const clearField = (form) => {
  let need = deepCopy(form)
  for (let k in need) {
    form[k] = ''
  }
  return form
}

/**
 * 压缩图片
 * @param file 图片
 * @param size 图片压缩比 0-1，数字越小，图片压缩越小，图片也会越模糊
 * @returns
 */
export const compressImg = (file, size = 0.3) => {
  return new Promise((resolve, reject) => {
    let img = new Image() // 创建新的图片对象
    img.src = file // 指定图片的DataURL(图片的base64编码数据)
    var width, height, base64Url
    img.onload = function() { // base64地址图片加载完成后执行
      width = img.width
      height = img.height
      // eslint-disable-next-line no-unused-vars
      var initSize = img.src.length
      var canvas = document.createElement('canvas')
      var ctx = canvas.getContext('2d')
      canvas.width = img.width
      canvas.height = img.height
      ctx.fillStyle = '#fff' // 铺底色
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(img, 0, 0, width, height)
      // 进行最小压缩后的base64
      base64Url = canvas.toDataURL('image/jpeg', size)
      // console.log('*******压缩前的图片大小*******', (initSize / 1024 / 1024) + 'M')
      // console.log('*******压缩后的图片大小*******', (base64Url.length / 1024) + 'kb')
      resolve(base64Url)
    }
  })
}

/**
 * 图片格式验证
 * @param file
 * @param size 单位M
 * @returns
 */
export const checkFormat = (file, size = 5) => {
  let typeArr = file.name.split('.')
  let type = typeArr[typeArr.length - 1]
  const isImage = type === 'jpeg' || type === 'jpg' || type === 'png' || type === 'gif'
  const isJPG = file.type === 'image/jpeg'
  const isPng = file.type === 'image/png'
  const isGif = file.type === 'image/gif'
  const isLimit = file.size / 1024 / 1024 < size
  if (file.type && !isJPG && !isPng && !isGif) {
    message.notify('上传图片格式错误')
    return false
  }
  if (!file.type && !isImage) {
    message.notify('上传图片格式错误')
    return false
  }
  if (!isLimit) {
    message.notify(`上传图片大小不能超过 ${size}MB`)
    return false
  }
  return (isJPG || isPng || isImage || isGif) && isLimit
}

/**
 * 判断是否是外部的链接
 * @param {string} path
 * @returns {Boolean}
 */

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * 表格合计
 * @param file
 * @returns
 */
export const summaryMethod = (param, sumColumn, sumText) => {
  const { columns, data } = param
  const sums = []
  columns.forEach((column, index) => {
    if (sumText && sumText.includes(column.property)) {
      sums[index] = '合计'
      return
    } else if (!sumText && index === 2) {
      sums[index] = '合计'
      return
    }
    const values = data.map(item => Number(item[column.property]))
    if (sumColumn && sumColumn.includes(column.property) && !values.every(value => isNaN(value))) {
      sums[index] = values.reduce((prev, curr) => {
        const value = Number(curr)
        if (!isNaN(value)) {
          return prev + curr
        } else {
          return prev
        }
      }, 0)
      sums[index] = parseFloat((sums[index]).toFixed(2))
    } else {
      sums[index] = ''
    }
  })
  return sums
}

/**
 * 导出
 */
export const exportData = (data, url) => {
  message.topMessage('正在导出...', 'warning', '', 0)
  data = {
    ...store.state.commonParams,
    ...data,
  }
  const instance = axios.create({
    withCredentials: true, // 跨域请求时发送Cookie
    baseURL: getApiUrl(),
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': 'Bearer ' + store.state.token.token
    },
    responseType: 'blob'
  })
  instance({
    url: url,
    method: 'get',
    params: data
  }).then(res => {
    message.topMsgClear()
    if (res.status == 200) {
      const { data, headers } = res
      const fileName = decodeURIComponent(headers['content-disposition'].replace(/\w+;filename="(.*)"/, '$1'))
      const blob = new Blob([data], {type: headers['content-type']})
      let dom = document.createElement('a')
      let url = window.URL.createObjectURL(blob)
      dom.href = url
      dom.download = decodeURI(fileName)
      dom.style.display = 'none'
      document.body.appendChild(dom)
      dom.click()
      dom.parentNode.removeChild(dom)
      window.URL.revokeObjectURL(url)
      message.topMessage('导出成功！')
    } else {
      message.topMessage('导出失败！')
    }
  }).catch(error => {
    message.topMsgClear()
    message.topMessage('导出出错，服务器开小差了呢')
  })
}

/**
 * 判断是否操作有权限
 * @param rule  01 查询、02 添加、03 编辑 、04 删除、05 审核 、06 反审核、07 导出、08 导入、09 打印
 */
export const checkRule = (rule) => {
  let rules = store.state.userAuth
  if (rule.length === 0) return true
  else return rule.some(rule => rules.includes(rule))
}

/**
 * 判断是否有菜单权限
 * @param rule
 */
export const checkMenuRule = (rule) => {
  let rules = store.state.menuAuth
  if (rule.length === 0) return true
  else return rule.some(rule => rules.includes(rule))
}

export default {
  setField,
  clearField,
  compressImg,
  checkFormat,
  isExternal,
  debounce,
  summaryMethod,
  exportData,
  checkRule,
  checkMenuRule,
}

