<template>
  <div>
    <!-- 展开/收起侧边菜单栏 -->
    <div class="fold-menu-btn">
      <div class="fold-menu-icon" @click="toggleSideBar">
        <i v-if="isCollapse" class="el-icon-s-unfold"/>
        <i v-else class="el-icon-s-fold"/>
      </div>
    </div>
    <!-- 侧边菜单栏 -->
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        mode="vertical"
        :collapse="isCollapse"
        :default-active="activeMenu"
        :unique-opened="true"
        :collapse-transition="true"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :active-text-color="variables.menuActiveText"
      >
        <side-bar-item v-for="route in $router.options.routes" :key="route.path" :item="route" :base-path="route.path" @toggleSideBar="toggleSideBar"/>
      </el-menu>
    </el-scrollbar>
    <div class="record-number">琼ICP备19004478号-1</div>
  </div>
</template>

<script>
import sideBarItem from './sideBarItem'
import variables from '../../../styles/variables.scss'

export default {
  name: 'SideBar',
  components: {
    sideBarItem
  },
  data() {
    return {
      sidebar: {
        opened: true,
        withoutAnimation: false
      }
    }
  },
  computed: {
    activeMenu() {
      const route = this.$route
      const {meta, path} = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    isCollapse() {
      this.changeSideBarW()
      return !this.$store.state.sidebarOpened
    },
    variables() {
      return variables
    }
  },
  watch: {
    isCollapse() {
      this.changeSideBarW()
    }
  },
  methods: {
    toggleSideBar() {
      this.$store.commit('toggle_side_bar')
    },
    changeSideBarW() {
      let open = this.$store.state.sidebarOpened
      let width = !Number(open) ? this.variables.sideBarFoldWidth : this.variables.sideBarWidth
      document.getElementsByTagName('body')[0].style.setProperty('--sideBarWidth', width)
    }
  }
}
</script>

<style lang="scss" src="./sidebar.scss" scoped/>
